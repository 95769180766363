<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="true"
      api-url="/goods/v1/admin-uid-list"
      api-download-url="/goods/v1/admin-uid-export"
      download-file-name="uid.xlsx"
      @showDialogHistory="showDialogHistory"
      @showRollDialog="showRollDialog"
    ></custom-table>

    <v-dialog v-model="historyDialog" max-width="1366px">
      <UidHistory
        v-if="historyDialog"
        :item="itemDetail"
        @cancel="hideDialogHistory"
      />
    </v-dialog>

    <v-dialog v-model="rollDialog" max-width="400px">
      <RollUid
        v-if="rollDialog"
        @cancel="hideRollDialog"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  GOODS_UID_SOURCE_TYPES,
  YES_NO_OPTIONS,
  GOODS_UID_STATUS_OPTIONS,
} from "@/libs/const";
export default {
  name: "Uid",
  components: {
    UidHistory: () => import("@/components/goods/UidHistory"),
    RollUid: () => import("@/components/goods/RollUid"),
  },
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    historyDialog: false,
    rollDialog: false,
    itemDetail: {},
    statusOptions: [...GOODS_UID_STATUS_OPTIONS],
    yesNoStatus: [...YES_NO_OPTIONS],
    sourceTypes: [...GOODS_UID_SOURCE_TYPES],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const columns = [
      {
        type: "select-customer",
        label: this.$t("labels.customer"),
        placeholder: this.$t("labels.customer"),
        name: "id_customer",
        hasSort: true,
        sortName: "company_name",
        key: "company_name",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.source"),
        placeholder: this.$t("labels.source"),
        name: "source_type",
        hasSort: true,
        sortName: "source_type",
        key: "source_name",
        options: this.sourceTypes,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.source_code"),
        placeholder: this.$t("labels.source_code"),
        name: "source_code",
        hasSort: true,
        sortName: "source_code",
        key: "source_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: true,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.uid"),
        placeholder: this.$t("labels.uid"),
        name: "keywords",
        hasSort: true,
        sortName: "sku",
        key: "sku_uid",
        required: true,
        actionPermissions: [],
        actionModules: [],
        action: "showDialogHistory",
      },
      {
        type: "input-filter",
        label: this.$t("labels.code_imei"),
        placeholder: this.$t("labels.code_imei"),
        name: "barcode",
        hasSort: true,
        sortName: "barcode",
        key: "barcode",
        required: true,
        actionPermissions: [],
        actionModules: [],
        action: "showDialogHistory",
      },
      {
        type: "select-filter",
        label: this.$t("labels.available"),
        placeholder: this.$t("labels.available"),
        name: "available",
        hasSort: true,
        sortName: "available",
        key: "available",
        options: this.yesNoStatus,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: true,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [
      {
        text: "Thu hồi",
        action: "showRollDialog",
        link: "",
        modules: [],
        permissions: [],
        color: "warning",
        class: "mr-2",
      },
    ];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogHistory(item) {
      this.itemDetail = { ...item };
      this.historyDialog = true;
    },
    hideDialogHistory() {
      this.itemDetail = {};
      this.historyDialog = false;
    },
    showRollDialog() {
      this.rollDialog = true;
    },
    hideRollDialog() {
      this.rollDialog = false;
    },
  },
};
</script>

<style scoped></style>
